
import Vue from "vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";

export default Vue.extend({
  name: "InputRadio",
  components: { InputWrapper },
  props: {
    value: String,
    fieldKey: String,
    field: Object,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    cancelSelectionOnSecondClick(event: any) {
      if (event.target.value === this.value) {
        this.$emit("input", "");
      }
    },
  },
});
