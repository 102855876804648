
import Vue from "vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";

export default Vue.extend({
  name: "InputCheck",
  components: { InputWrapper },
  props: {
    fieldKey: String,
    field: Object,
  },
  methods: {
    onChange() {
      this.$emit("change", this.field.value);
    },
  },
});
