
import Vue from "vue";
import Header from "@/components/Header.vue";
// import { MapMutations } from "@/store/map/mutations";
export default Vue.extend({
  name: "LegalMentions",
  components: {
    Header,
  },
});
