
import Vue from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapboxInstance } from "@/mapbox";
// @ts-ignore
import { filterGeocoderResults, isRegion } from "@/utils/utils";
export default Vue.extend({
  name: "Home",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      informationDetail: {
        "competences-numeriques":
          "La capacité à utiliser le numérique est fortement liée avec l’âge et le niveau de diplôme. Les variables “part des personnes âgées” et “personnes peu ou pas diplômées” donnent un indicateur de la part de la population qui risque d’être en difficulté.",
        "acces-numeriques":
          "Pour pouvoir utiliser le numérique, il faut un équipement (dont le coût peut être élevé) et un accès à un réseau de bonne qualité (couverture en très haut débit).",
        "competences-admin":
          "Les compétences administratives sont essentielles dans l’accès au droit. Or, les plus précaires et les plus jeunes connaissent des difficultés dans l’accomplissement des démarches administratives. Le numérique vient donc renforcer des difficultés préexistantes, notamment chez ces publics.",
        "acces-information":
          "Les personnes vivant seules ont une tendance à avoir plus de difficultés avec les démarches administratives car elles bénéficient moins de l’assistance d’un proche.",
      },
      informationTitle: {
        "competences-numeriques": "Compétences numériques",
        "acces-numeriques": "Accès au numérique",
        "competences-admin": "Compétences administratives",
        "acces-information": "Accès à l'information",
      },
      isSearchedDepartement: "",
      lat: null,
      lng: null,
      searchedText: "",
      selectedInformationDetail: null,
    };
  },
  mounted() {
    this.$store.commit("INIT_GEOCODER", "home");
    mapboxInstance.geocoder!.setFilter(filterGeocoderResults);
    mapboxInstance.geocoder!.on("result", (e: any) => {
      this.lat = e.result.center[1];
      this.lng = e.result.center[0];
      this.searchedText = e.result.text;
      this.isSearchedDepartement = isRegion(e.result);
      this.trackResult(e.result.text);
    });
  },
  methods: {
    trackResult(search: string): void {
      // @ts-ignore
      this.$matomo && this.$matomo.trackSiteSearch(search, false, 1);
    },
    searchResult(): void {
      if (!this.lat && !this.lng) {
        return;
      }
      this.$store.commit("SET_SEARCH_CENTER", {
        isDepartement: this.isSearchedDepartement,
        lng: this.lng,
        lat: this.lat,
        searchedText: this.searchedText,
      });
      mapboxInstance.geocoder!.off("result", () => {});
      this.$router.push("/carte");
    },
  },
});
