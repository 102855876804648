
import Vue from "vue";

export default Vue.extend({
  name: "MapContent",
  components: {},
  computed: {
    currentCity: {
      get(): string {
        return this.$store.state.map.currentCity;
      },
    },
    cityPopulation(): string {
      return Intl.NumberFormat().format(this.$store.state.map.population);
    },
    informationsAccess: {
      get(): number {
        return Math.round(this.$store.state.map.score.informationsAccess);
      },
    },
    globalIndicator: {
      get(): number {
        return Math.round(this.$store.state.map.score.total);
      },
    },
    numericAccess: {
      get(): number {
        return Math.round(this.$store.state.map.score.numericAccess);
      },
    },
    numericSkills: {
      get(): number {
        return Math.round(this.$store.state.map.score.numericSkills);
      },
    },
    administrativeSkills: {
      get(): number {
        return Math.round(this.$store.state.map.score.administrativeSkills);
      },
    },
    population: {
      get(): number {
        return Math.round(this.$store.state.map.score.population);
      },
    },
  },
  methods: {
    arrowPositionLeft() {
      const imageWidth = (document.getElementById("palette-image") || {}).clientWidth || 0;
      if (!imageWidth) {
        // if the image has not yet been loaded, re-render component some time later,
        //   so that this method will be called again
        setTimeout(() => this.$forceUpdate(), 200);
      }
      const toReturn = 3 + ((this.$store.state.map.score.total + 10) / 20) * imageWidth + "px";
      return toReturn;
    },
    switchModal() {
      this.$store.commit("SET_SEEN_MODAL");
      this.$store.commit("SET_MODAL");
      this.$store.commit("SET_CHANGE_STEP_NUMBER", { step: 1 });
    },
  },
});
