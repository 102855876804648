
import Vue from "vue";
// @ts-ignore
import {
  PARTNERS_FIELDS, // @ts-ignore
} from "@/utils/sidebarData";
import InstitutionsMixin from "@/mixins/InstitutionsMixin";
import InputRadio from "@/components/inputs/InputRadio.vue";

export default Vue.extend({
  name: "PartnersFilter",
  components: { InputRadio },
  mixins: [InstitutionsMixin],
  data() {
    return {
      PARTNERS_FIELDS: PARTNERS_FIELDS,
      isOpened: true,
    };
  },
  computed: {
    filters: {
      get() {
        return this.$store.state.map.partnersFilters;
      },
      set(newValue: string[]) {
        this.updateFilters(newValue);
      },
    },
  },
  methods: {
    updateFilters(newValue: string[]) {
      this.$store.commit("SET_PARTNERS_FILTERS", newValue);
      this.setPartnersSource(newValue);
    },
  },
});
