
import Vue from "vue";

export default Vue.extend({
  name: "MapContent",
  components: {},
  computed: {
    getStep: {
      get(): string {
        return this.$store.state.global.step;
      },
    },
  },
  methods: {
    switchModal() {
      this.$store.commit("SET_SEEN_MODAL");
      this.$store.commit("SET_MODAL");
    },
    changeStep(operator: string) {
      this.$store.commit("SET_STEP_NUMBER", { step: operator });
    },
  },
});
