
import Vue from "vue";
import InstitutionsMixin from "@/mixins/InstitutionsMixin";
import Cross from "@/components/logo/cross.vue";

const DAYS = {
  monday: "Lundi",
  thuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  Sunday: "Dimanche",
};
export default Vue.extend({
  name: "InstitutionInformation",
  components: { Cross },
  mixins: [InstitutionsMixin],
  props: {
    modalInformation: { type: Object },
  },
  data() {
    return {
      DAYS,
    };
  },
  computed: {
    openingTime() {
      return Object.entries(DAYS)
        .filter(([key]) => (this.modalInformation as any)[`hourly_${key}`])
        .map(([key, day]) => {
          return `${day} : ${(this.modalInformation as any)[`hourly_${key}`]}`;
        })
        .join("</br>");
    },
  },
  methods: {
    closeWindow() {
      this.$store.commit("SET_MODAL_INFORMATION", null);
    },
  },
});
