
import Vue from "vue";
import InstitutionInformation from "@/components/InstitutionInformation.vue";
import ScoreInformation from "@/components/ScoreInformation.vue";

export default Vue.extend({
  name: "ModalInformation",
  components: { ScoreInformation, InstitutionInformation },
  data() {
    return {};
  },
  computed: {
    modalInformation() {
      return this.$store.state.map.modalInformation;
    },
  },
  methods: {
    closeWindow() {
      this.$store.commit("SET_MODAL_INFORMATION", null);
    },
  },
});
