
import Vue from "vue";

export default Vue.extend({
  name: "Cross",
  props: {
    fill: {
      type: String,
      default: "white",
    },
  },
});
