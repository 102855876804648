<template>
  <div class="map__footer">
    <div class="">© 2021 MedNum. Tous droits réservés</div>
    <div>
      <router-link to="/" rel="noopener noreferrer">Accueil </router-link>
      <router-link to="/mentions-legales" rel="noopener noreferrer">Mentions légales </router-link>
    </div>
    <div>
      <a
        href="https://lamednum.coop/"
        target="_blank"
        rel="noopener noreferrer"
        title="Notre coopérative"
        >Notre coopérative</a
      >
      <a
        href="https://lamednum.coop/actions/indice-de-fragilite-numerique/"
        target="_blank"
        rel="noopener noreferrer"
        title="Notre coopérative"
        >Contact</a
      >
    </div>
    <div>
      Site réalisé avec engagement par
      <a href="https://www.telescoop.fr/" target="_blank">TelesCoop</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapFooter",
};
</script>

<style scoped>
.map__footer {
  padding-top: 30px;
  color: black;
  font-weight: bold;
}
.map__footer a {
  color: black;
}
.map__footer a:hover {
  text-decoration: underline;
}
.map__footer a:not(:last-child) {
  margin-right: 10px;
}
</style>
