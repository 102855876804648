
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios";
import Vue from "vue";
import {
  colorClassFromScore,
  filterGeocoderResults,
  isRegion,
  isSiteMH,
  levenshtein, // @ts-ignore
} from "@/utils/utils.js";
// @ts-ignore
import { computeColorFromScoring } from "../utils/colors";
// @ts-ignore
import { ZOOM_LEVELS } from "@/utils/constants";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  Geometry, // eslint-disable-next-line no-unused-vars
  ICityData, // eslint-disable-next-line no-unused-vars
  ICityScore, // eslint-disable-next-line no-unused-vars
  ICityScoreWithColor,
} from "@/types/map";
import { mapboxInstance } from "@/mapbox";
// @ts-ignore
import { FIELDS_CONFIGURATION } from "@/utils/sidebarData";
import InstitutionsMixin from "@/mixins/InstitutionsMixin";
import ModalInformation from "@/components/ModalInformation.vue";

const BASE_API_URL = process.env.VUE_APP_BASE_API_URL || "/api";

let MAP_LISTENERS: { [key: string]: boolean } = {};
const citiesCenter: any = [
  ["Paris", [2.34, 48.85], 75101],
  ["Toulouse", [1.43, 43.6], 31555],
  ["Bordeaux", [-0.57918, 44.837789], 33063],
  ["Lyon", [4.835659, 45.764043], 69381],
  ["Marseille", [5.36978, 43.296482], 13201],
  ["Nantes", [-1.553621, 47.218371], 44109],
  ["Strasbourg", [7.74553, 48.58392], 67482],
  ["Nancy", [6.18496, 48.68439], 54395],
];
const FETCHING_DEBOUNCE = 300;

let firstLayerId: string;

export default Vue.extend({
  components: { ModalInformation },
  mixins: [InstitutionsMixin],
  name: "Map",
  computed: {
    activeDivision() {
      return this.$store.state.map.activeDivision;
    },
  },
  methods: {
    addCircleSourceIfNotPresent(): void {
      if (mapboxInstance.map!.getSource("circles")) {
        return;
      }

      console.log("### adding circles source");
      mapboxInstance.map!.addSource("circles", {
        type: "geojson", // @ts-ignore
        data: this.mhCircleData,
      });
      mapboxInstance.map!.addLayer({
        id: "circles",
        type: "circle",
        source: "circles",
        paint: {
          "circle-color": "#8090de",
          "circle-radius": {
            property: "population_sqrt",
            stops: [
              [{ zoom: 8, value: 0 }, 0],
              [{ zoom: 8, value: 250 }, 15],
              [{ zoom: 11, value: 0 }, 0],
              [{ zoom: 11, value: 250 }, 90],
              [{ zoom: 16, value: 0 }, 0],
              [{ zoom: 16, value: 250 }, 360],
            ],
          },
          "circle-stroke-width": 1,
          "circle-stroke-color": "black",
          "circle-opacity": 0.5,
        },
      });
    },

    addGeometry(geometry: Geometry): void {
      // add source if necessary
      if (!(geometry.code in this.addedGeometries)) {
        this.addedGeometries[geometry.code] = true;
      }

      // add source if necessary
      if (mapboxInstance.map && !mapboxInstance.map.getSource(geometry.code)) {
        // @ts-ignore
        window.mapbox = mapboxInstance.map;
        const source = {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "MultiPolygon",
              coordinates: geometry.coordinates,
            },
            properties: {},
          },
        };
        // @ts-ignore
        mapboxInstance.map.addSource(geometry.code, source);
      }

      // add city layer if necessary
      if (mapboxInstance.map && !mapboxInstance.map.getLayer(geometry.code)) {
        // Ref : https://docs.mapbox.com/mapbox-gl-js/example/geojson-layer-in-stack/
        let layers = mapboxInstance.map.getStyle().layers;
        // Find the index of the first symbol layer in the map style
        if (firstLayerId == null) {
          for (var i = 0; i < layers!.length; i++) {
            if (layers![i].type === "symbol") {
              firstLayerId = layers![i].id;
              break;
            }
          }
        }
        const layer = {
          id: geometry.code,
          type: "fill",
          source: geometry.code,
          sourceLayer: "cities_boundaries",
          paint: {
            "fill-color": "red",
            "fill-opacity": 0.0,
            "fill-outline-color": "white",
          },
        };
        // @ts-ignore
        mapboxInstance.map.addLayer(layer, firstLayerId);
      }

      if (mapboxInstance.map!.getLayer(geometry.code)) {
        mapboxInstance.map!.setLayoutProperty(geometry.code as any, "visibility", "visible");
      }
      if (!MAP_LISTENERS[geometry.code]) {
        mapboxInstance.map!.on("click", geometry.code, (e) => {
          if (e.originalEvent.cancelBubble) {
            return;
          }
          this.showModalForInseeCode(geometry.code);
        });
        mapboxInstance.map!.on("mouseenter", geometry.code, () => {
          this.onMapHover(geometry.code);
        });
        MAP_LISTENERS[geometry.code] = true;
      }

      this.$store.commit("SET_SHOWING_CITY", {
        [geometry.code]: true,
      });
    },
    addMHPopulationToCircleFeaturesIfNotPresent(
      inseeCode: string,
      population: number,
      coordinates: Array<number>
    ) {
      // @ts-ignore
      if (this.inseeCodeHasMHCircle[inseeCode]) {
        return;
      }
      this.addCircleSourceIfNotPresent();

      this.mhCircleFeatures.push({
        // @ts-ignore
        type: "Feature", // @ts-ignore
        properties: {
          // @ts-ignore
          inseeCode: inseeCode,
          // @ts-ignore
          population_sqrt: Math.sqrt(population),
        }, // @ts-ignore
        geometry: {
          // @ts-ignore
          coordinates: coordinates, // @ts-ignore
          type: "Point",
        },
      });

      // @ts-ignore
      this.inseeCodeHasMHCircle[inseeCode] = true;
    },
    showScoreInformation(cityData: ICityData): void {
      this.$store.commit("SET_MODAL_INFORMATION", { ...cityData, modalType: "score" });
    },

    doFetchScores() {
      if (this.$store.state.map.isLoadingScores) {
        // we're currently fetching, so we'll wait before fetching again
        setTimeout(() => this.doFetchScores(), FETCHING_DEBOUNCE);
        console.log("### we're currently fetching, so we'll wait before fetching again");
        return;
      }
      if (new Date().getTime() - this.fetchingScore.lastFetchingOrderDate < FETCHING_DEBOUNCE) {
        // there has been a more recent order, we ignore this one
        console.log("### there has been a more recent order, we ignore this one");
        return;
      }
      console.log("### alright, let's fetch");
      return this.$store
        .dispatch("FETCH_SCORES", this.fetchingScore.lastFetchingOrder.inseeCodes)
        .then((res) => {
          console.log("### done fetching");
          const inseeCodeToSelect = this.fetchingScore.lastFetchingOrder.inseeCodeToSelect;
          const inseeCodeForPopup = this.fetchingScore.lastFetchingOrder.inseeCodeForPopup;
          for (const [inseeCode, value] of Object.entries(res.data) as any) {
            if (inseeCode == inseeCodeToSelect) {
              console.log("### selecting", inseeCodeToSelect);
              this.$store.commit("SET_GLOBAL_SCORE", value);
              this.setSelectedBorder(inseeCodeToSelect);
            }
            this.updateLayer(inseeCode, value);
          }
          if (inseeCodeForPopup !== "0") {
            let cityData: ICityData = res.data[inseeCodeForPopup];
            cityData.scoreWithColor = this.normalizeCityScore(cityData.score);
            console.log("### inseeCodeForPopup ", inseeCodeForPopup, " score is", cityData);
            this.showScoreInformation(cityData);
          }
          if (this.isMH) {
            this.updateMHCircleFeatures();
          }
        });
    },
    fetchScores(inseeCodes: Array<string>, inseeCodeToSelect = "0", inseeCodeForPopup = "0") {
      console.log("### fetchScores", inseeCodes, inseeCodeToSelect, inseeCodeForPopup);
      this.fetchingScore.lastFetchingOrder = { inseeCodes, inseeCodeToSelect, inseeCodeForPopup };
      this.fetchingScore.lastFetchingOrderDate = new Date().getTime();
      setTimeout(() => this.doFetchScores(), FETCHING_DEBOUNCE);
    },
    getCityInseeCodeFromBoundaries(geometries: any, textToFind: string): string {
      let inseeCode: null | string = "";
      let lowestScore = 99;
      for (const el of geometries) {
        let distance = levenshtein(textToFind, el.name);
        if (
          distance < lowestScore ||
          distance === 0 ||
          el.name.substr(0, textToFind.length) === textToFind
        ) {
          lowestScore = distance;
          inseeCode = el.code as any;
          if (distance === 0 || el.name.substr(0, textToFind.length) === textToFind) {
            break;
          }
        }
      }
      return inseeCode!;
    },
    getGeometries(hasSearch: boolean) {
      console.log("### get geometries", this.$store.state.map.activeDivision);
      return this.$store
        .dispatch("GET_GEOMETRIES", {
          boundaries: {
            north: this.$store.state.map.bounds[0],
            east: this.$store.state.map.bounds[1],
            south: this.$store.state.map.bounds[2],
            west: this.$store.state.map.bounds[3],
          },
        })
        .then((response) => {
          const geometries = response.data.geometries;
          this.updateGeometries(geometries);
          if (hasSearch) {
            const inseeCode = this.getCityInseeCodeFromBoundaries(
              response.data.geometries,
              this.$store.state.map.search.searchedText
            );
            return inseeCode;
          }
        });
    },
    initMap(): void {
      const hasSearch = this.$store.state.map.search.hasSearch;
      const city = citiesCenter[Math.floor(Math.random() * (citiesCenter.length - 1))];
      this.$store.commit("INIT_MAP");
      this.$store.commit("INIT_GEOCODER");
      mapboxInstance.map!.on("load", () => {
        // update map on first instance
        this.updateMapData(hasSearch, String(city[2]));

        this.addFranceServices();
        this.addPartners();

        // update map on move end
        mapboxInstance.map!.on("moveend", () => {
          console.log("### after move end", mapboxInstance.map!.getZoom());
          if (!this.isOnMapMoveDisabled) {
            this.updateMapData(this.$store.state.map.search.hasSearch);
          }
        });
      });
    },
    initGeocoderResults(): void {
      this.onResultsEvent();
    },
    normalizeCityScore(cityScore: ICityScore): ICityScoreWithColor {
      const scoreWithColor: ICityScoreWithColor = {};
      for (const [key, value] of Object.entries(cityScore) as any) {
        // @ts-ignore
        if (typeof cityScore[key] === "number") {
          // @ts-ignore
          scoreWithColor[key] = {
            color: colorClassFromScore(value),
            score: Math.round(parseFloat(value) * 10) / 10,
          };
        } else {
          scoreWithColor[key] = {
            color: colorClassFromScore(value),
            score: undefined,
          };
        }
      }
      return scoreWithColor;
    },
    onMapHover(inseeCode: string): void {
      this.setHoverBorder(inseeCode);
    },
    async onResultsEvent() {
      if (mapboxInstance.geocoder) {
        console.log("### init geocoder");
        mapboxInstance.geocoder.setFilter(filterGeocoderResults);
        mapboxInstance.geocoder.on("result", async (e: any) => {
          console.log("### on result", e, e.result.text);
          let inseeCode: string = "";
          const isDepartement = isRegion(e.result);
          this.isOnMapMoveDisabled = true;

          if (isDepartement) {
            mapboxInstance.map!.zoomTo(ZOOM_LEVELS.departement, { duration: 0 });
            await new Promise((resolve) => setTimeout(resolve, 500));
            await this.$store.dispatch("SELECT_DIVISION", "auto");
          } else {
            mapboxInstance.map!.zoomTo(ZOOM_LEVELS.city + 0.1, { duration: 0 });
            await new Promise((resolve) => setTimeout(resolve, 500));
            await this.$store.dispatch("SELECT_DIVISION", "auto");
          }

          mapboxInstance.map!.flyTo({ center: e.result.center, speed: 10 });
          await new Promise((resolve) => setTimeout(resolve, 500));

          const boundaries: number[] = e.result.bbox;
          this.$store
            .dispatch("GET_GEOMETRIES", {
              boundaries: {
                north: boundaries[3],
                east: boundaries[2],
                south: boundaries[1],
                west: boundaries[0],
              },
            })
            .then((response) => {
              if (response.data && response.data.geometries !== undefined) {
                /* here we use the Levenshtein distance to find the degrees of similarity between two chains of characters. */
                inseeCode = this.getCityInseeCodeFromBoundaries(
                  response.data.geometries,
                  e.result.text
                );
                console.log("### found insee code", inseeCode);
                if (!isDepartement) {
                  console.log("### is city, ", inseeCode);
                  this.updateMapData(false, inseeCode);
                } else {
                  console.log("### is departement, ", inseeCode);
                  this.updateMapData(false, inseeCode);
                }
                this.trackResult(e.result.text);
                this.isOnMapMoveDisabled = false;
              }
            });
        });
      } else {
        setTimeout(() => this.onResultsEvent(), 200);
      }
    },
    removeHover() {
      if (mapboxInstance.map?.getLayer("hover")) {
        mapboxInstance.map?.removeLayer("hover");
      }
    },
    scoreOrUnknown(score: number): string {
      if (score == null) {
        return "inconnu";
      }
      return String(score);
    },
    showModalForInseeCode(inseeCode: string): void {
      console.log("Click on", inseeCode);
      this.fetchScores([inseeCode], inseeCode, inseeCode);
    },
    async selectDivision(division: String) {
      console.log("### set active division");
      this.isDivisionSelectionDetails = false;
      await this.$store.dispatch("SELECT_DIVISION", division);
      await this.updateMapData();
    },
    setHoverBorder(inseeCode: string) {
      this.removeHover();
      mapboxInstance.map!.addLayer({
        id: "hover",
        type: "line",
        source: inseeCode,
        paint: {
          "line-color": "#999",
          "line-width": 3,
        },
      });
    },
    setSelectedBorder(inseeCode: string) {
      console.log("### set border", inseeCode);
      if (mapboxInstance.map?.getLayer("selected")) {
        mapboxInstance.map?.removeLayer("selected");
      }
      mapboxInstance.map!.addLayer({
        id: "selected",
        type: "line",
        source: inseeCode,
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      });
    },
    trackResult(search: string) {
      // @ts-ignore
      this.$matomo && this.$matomo.trackSiteSearch(search, false, 1);
    },
    updateGeometries(geometries: any): void {
      const newGeometries: { [key: string]: boolean } = {};
      for (const geometry of geometries) {
        newGeometries[geometry.code as any] = true;
        this.addGeometry(geometry);
      }
      // remove geometries that should no longer be shown
      for (const code in this.$store.state.map.showingCities) {
        if (!this.$store.state.map.showingCities[code as any]) {
          continue;
        }
        if (!(code in newGeometries) && mapboxInstance.map!.getLayer(code as any)) {
          mapboxInstance.map!.setLayoutProperty(code as any, "visibility", "none");
          this.$store.commit("SET_SHOWING_CITY", {
            [code]: false,
          });
        }
      }
    },
    updateLayer(inseeCode: string, data: any) {
      const score = data.score && data.score.total;
      if (mapboxInstance.map?.getLayer(inseeCode)) {
        const layerProperties = computeColorFromScoring(score);
        mapboxInstance.map?.setPaintProperty(inseeCode, "fill-color", layerProperties);
        const opacity = score == null ? 0 : 0.8;
        mapboxInstance.map?.setPaintProperty(inseeCode, "fill-opacity", opacity);
      }
      if (this.isMH && data.info.mh_population) {
        this.addMHPopulationToCircleFeaturesIfNotPresent(
          inseeCode,
          data.info.mh_population,
          data.info.center
        );
      }
    },
    updateMapBounds(): void {
      this.$store.commit("UPDATE_MAP_BOUNDS");
    },
    async updateMapData(hasSearch = false, inseeCodeToSelect: any = "0"): Promise<any> {
      console.log("### updateMapData, inseecode?", inseeCodeToSelect, hasSearch);
      await this.$store.dispatch("UPDATE_ACTIVE_DIVISION");
      await this.updateMapBounds();
      const foundInseeCode = await this.getGeometries(hasSearch);
      console.log("### foundInseeCode", foundInseeCode);
      if (hasSearch) {
        inseeCodeToSelect = foundInseeCode;
      }
      this.updateScore(inseeCodeToSelect);
    },
    updateMHCircleFeatures() {
      const keepFeatures = this.mhCircleFeatures.filter((feature) => {
        // @ts-ignore
        return !!this.$store.state.map.showingCities[feature.properties.inseeCode];
      });
      this.mhCircleData.features = keepFeatures;
      // @ts-ignore
      mapboxInstance.map!.getSource("circles").setData(this.mhCircleData);
    },
    updateScore(inseeCodeToSelect = "0") {
      console.log("### updateScore fetchScores", inseeCodeToSelect);
      this.fetchScores(
        Object.keys(this.$store.state.map.showingCities).filter(
          (k) => this.$store.state.map.showingCities[k] === true
        ),
        inseeCodeToSelect
      );
    },
  },
  data() {
    return {
      addedGeometries: {} as { [key: string]: boolean },
      divisionNames: {
        city: "Commune",
        epci: "EPCI",
        departement: "Département",
      },
      fetchingScore: {
        lastFetchingOrderDate: new Date().getTime() - FETCHING_DEBOUNCE,
        lastFetchingOrder: {
          inseeCodes: ["0"],
          inseeCodeForPopup: "0",
          inseeCodeToSelect: "0",
        },
      },
      inseeCodeHasMHCircle: {},
      isDivisionSelectionDetails: false,
      isMH: isSiteMH(),
      isOnMapMoveDisabled: false,
      fieldsConfiguration: FIELDS_CONFIGURATION,
      mhCircleData: {
        type: "FeatureCollection",
        features: [],
      },
      mhCircleFeatures: [],
      showingGeometries: {} as { [key: string]: boolean },
    };
  },

  mounted() {
    axios.get(`${BASE_API_URL}/version`).then(() => {});
    this.initMap();
    this.initGeocoderResults();
  },
  beforeDestroy() {
    this.$store.commit("SET_CURRENT_CITY", null);
    MAP_LISTENERS = {};
  },
});
