<template>
  <header id="header">
    <div class="logo">
      <div style="display: flex; flex-direction: column">
        <a
          class="logo"
          href="/"
          title="Portail de la fragilité numérique un outils de la MedNum"
          style="padding-bottom: 10px"
        >
          <img
            src="../assets/logo.png"
            alt="Logo Banque des territoires"
            style="max-width: 200px"
          />
        </a>
        <a class="logo" href="/" title="Portail de la fragilité numérique un outils de la MedNum">
          réalisé avec
          <img
            src="../assets/logo_mednum.png"
            alt="Logo MedNum"
            style="max-width: 200px; padding-left: 10px"
          />
        </a>
      </div>
    </div>
    <nav class="menu">
      <ul class="menu__list">
        <li class="menu__item"> <router-link to="/carte">Cartographie </router-link></li>
        <li class="menu__item"
          ><a title="Clés de lecture" @click="scrollMeTo('reading-keys')">Clés de lecture</a>
        </li>
        <li class="menu__item"
          ><a @click="scrollMeTo('use-cases')" title="Cas d'usage">Cas d'usage</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  methods: {
    scrollMeTo(refName) {
      let element = document.getElementById(refName);
      let top = element.offsetTop;
      window.scrollTo(0, top);
    },
  },
  mounted() {},
};
</script>

<style scoped>
/* HEADER */
#header {
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #fff6f6 0%, rgba(255, 247, 247, 0) 100%);
  padding: 30px;
  display: flex;
}
.logo {
  display: inline-flex;
}
.menu {
  width: 80%;
}
.menu__list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu__item {
  list-style: none;
  padding-right: 40px;
}
.menu a {
  width: auto;
  font-family: "Roboto";
  font-weight: 700;
  color: #1e2c78;
  font-size: 22px;
}
.menu > ul > li.active > a {
  color: #529f44;
}
@media only screen and (max-width: 700px) {
  #header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logo {
    margin-bottom: 10px;
  }
  .menu__list {
    text-align: right;
    flex-direction: column;
  }
  .menu__item {
    text-align: center;
    padding: 5px;
  }
}
</style>
