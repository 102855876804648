
import Vue from "vue";
// @ts-ignore
import {
  FS_FIELDS, // @ts-ignore
} from "@/utils/sidebarData";
import InstitutionsMixin from "@/mixins/InstitutionsMixin";
import InputCheckList from "@/components/inputs/InputCheckList.vue";

export default Vue.extend({
  name: "Sidebar",
  components: { InputCheckList },
  mixins: [InstitutionsMixin],
  data() {
    return {
      FS_FIELDS: FS_FIELDS,
      isOpened: true,
    };
  },
  computed: {
    filters: {
      get() {
        return this.$store.state.map.fsFilters;
      },
      set(newValue: string[]) {
        this.updateFilters(newValue);
      },
    },
  },
  methods: {
    updateFilters(newValue: string[]) {
      this.$store.commit("SET_FS_FILTERS", newValue);
      this.setFranceServicesSource(newValue);
    },
  },
});
