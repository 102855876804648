import { mapboxInstance } from "@/mapbox";
import mapboxgl from "mapbox-gl";
import Vue from "vue";
import {
  FS_FIELDS, // @ts-ignore
  PARTNERS_COLOR, // @ts-ignore
  PARTNERS_FIELDS, // @ts-ignore
  FS_SOURCE_FILE_NAME, // @ts-ignore
} from "@/utils/sidebarData";

const EMPTY_DATA = {
  type: "FeatureCollection",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [],
};
const FRANCE_SERVICES_ID = "france-services";
const PARTNERS_ID = "partners";
const INSTITUTIONS_STATIC_URL = `${process.env.VUE_APP_BASE_STATIC_URL}/mednum/institutions/`;

const LAYER_NAMES = {
  clusters: "-clusters",
  clustersCount: "-clusters-count",
  unclusteredPoints: "-unclustered-points",
  markerIcon: "-marker-icon",
};

function addClusters(id: string, color: string) {
  if (!mapboxInstance?.map) return;

  mapboxInstance.map.addLayer({
    id: `${id}${LAYER_NAMES.clusters}`,
    type: "circle",
    source: id,
    filter: ["has", "point_count"],
    paint: {
      "circle-color": color,
      "circle-radius": ["step", ["get", "point_count"], 12, 100, 15, 250, 20],
    },
  });

  mapboxInstance.map.addLayer({
    id: `${id}${LAYER_NAMES.clustersCount}`,
    type: "symbol",
    source: id,
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["Arial Unicode MS Bold", "DIN Offc Pro Medium"],
      "text-size": 12,
    },
    paint: {
      "text-color": "#ffffff",
    },
  });
}

function addFranceServicesMarkers(id: string) {
  if (!mapboxInstance?.map) return;

  mapboxInstance.map.addLayer({
    id: `${id}${LAYER_NAMES.unclusteredPoints}`,
    type: "circle",
    source: id,
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-color": [
        "case",
        ["==", ["get", "fs_statut"], FS_FIELDS.mobile.value],
        FS_FIELDS.mobile.color,
        FS_FIELDS.static.color,
      ],
      "circle-radius": 8,
    },
  });
}

function addPartnersMarkers(id: string) {
  if (!mapboxInstance?.map) return;

  mapboxInstance.map.loadImage("partners-marker.png", (error, image) => {
    if (!mapboxInstance?.map) return;
    if (error) throw error;

    const imageName = `${PARTNERS_ID}${LAYER_NAMES.markerIcon}`;

    // @ts-ignore
    mapboxInstance.map.addImage(imageName, image);

    mapboxInstance.map.addLayer({
      id: `${id}${LAYER_NAMES.unclusteredPoints}`,
      type: "symbol",
      source: id,
      filter: ["!", ["has", "point_count"]],
      layout: {
        "icon-image": imageName,
        "icon-size": 1.25,
      },
    });
  });
}

function addSource(id: string, dataUrl: string) {
  if (!mapboxInstance?.map) return;

  mapboxInstance.map.addSource(id, {
    type: "geojson",
    data: dataUrl,
    cluster: true,
    clusterMaxZoom: 9, // Max zoom to cluster points on
    clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
  });
}

function getColorOfInstitution(institution: any) {
  if (institution.partner_type === "France Service") {
    const color = FS_FIELDS[institution["fs_statut"]].color;
    return color;
  }
  return PARTNERS_COLOR;
}

function popupBuilder(coordinates: any, properties: any) {
  const color = getColorOfInstitution(properties);

  new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setHTML(
      `
                <div class="map-popup-header" style="background-color: ${color};">${
        properties.partner_type !== "France Service" ? `<div>${properties.partner_type}</div>` : ""
      }<div>${properties.name}</div></div>
                <div class="map-popup-content">${properties.postal_code} ${properties.city}</div>
            `
    )
    // @ts-ignore
    .addTo(mapboxInstance.map);
}

function showPopupOnMarkerClick(id: string, store: any) {
  if (!mapboxInstance?.map) return;

  mapboxInstance.map.on("click", `${id}${LAYER_NAMES.unclusteredPoints}`, (e) => {
    if (!e) return;
    // @ts-ignore
    const coordinates = e.features[0].geometry.coordinates.slice();
    // @ts-ignore
    const properties = e.features[0].properties;

    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    if (!properties) return;

    store.commit("SET_MODAL_INFORMATION", properties);
    popupBuilder(coordinates, properties);

    e.originalEvent.cancelBubble = true;
  });
}

function zoomOnClusterClick(id: string) {
  if (!mapboxInstance?.map) return;

  mapboxInstance.map.on("click", `${id}${LAYER_NAMES.clusters}`, (e) => {
    if (!mapboxInstance?.map) return;
    const features = mapboxInstance.map.queryRenderedFeatures(e.point, {
      layers: [`${id}-clusters`],
    });
    const clusterId = features[0].properties?.cluster_id;
    // @ts-ignore
    mapboxInstance.map.getSource(id).getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err || !mapboxInstance?.map) return;

      mapboxInstance.map.easeTo({
        // @ts-ignore
        center: features[0].geometry.coordinates,
        zoom: zoom,
      });
    });
    e.originalEvent.cancelBubble = true;
  });
}

export default Vue.mixin({
  methods: {
    addFranceServices() {
      if (!mapboxInstance?.map) return;

      const id = FRANCE_SERVICES_ID;
      // @ts-ignore
      const url = this.getFranceServiceSourceUrl(this.$store.state.map.fsFilters);

      addSource(id, url);
      addClusters(id, FS_FIELDS.static.color);
      addFranceServicesMarkers(id);
      zoomOnClusterClick(id);
      showPopupOnMarkerClick(id, this.$store);
    },

    addPartners() {
      if (!mapboxInstance?.map) return;

      const id = PARTNERS_ID;
      // @ts-ignore
      const url = this.getPartnersSourceUrl(this.$store.state.map.partnersFilters);

      addSource(id, url);
      addClusters(id, PARTNERS_COLOR);
      addPartnersMarkers(id);
      zoomOnClusterClick(id);
      showPopupOnMarkerClick(id, this.$store);
    },

    getColorOfInstitution: getColorOfInstitution,

    getFranceServiceSourceUrl(params: string[]) {
      if (!params.length) return EMPTY_DATA;
      let sourceFileName = "";
      if (params.length === 2) {
        sourceFileName = FS_SOURCE_FILE_NAME;
      } else {
        sourceFileName = FS_FIELDS[params[0]].sourceFileName;
      }

      return `${INSTITUTIONS_STATIC_URL}${sourceFileName}`;
    },

    setFranceServicesSource(filters: string[]) {
      if (!mapboxInstance?.map) return;

      // @ts-ignore
      const url = this.getFranceServiceSourceUrl(filters);
      // @ts-ignore
      mapboxInstance.map.getSource(FRANCE_SERVICES_ID).setData(url);
    },

    getPartnersSourceUrl(category: string) {
      if (!category) {
        return EMPTY_DATA;
      }

      // @ts-ignore
      const sourceFileName = Object.values(PARTNERS_FIELDS).find(
        (partner: any) => partner.name === category
      ).sourceFileName;
      return `${INSTITUTIONS_STATIC_URL}${sourceFileName}`;
    },

    setPartnersSource(filters: string[]) {
      if (!mapboxInstance?.map) return;

      // @ts-ignore
      const url = this.getPartnersSourceUrl(filters);
      // @ts-ignore
      mapboxInstance.map.getSource(PARTNERS_ID).setData(url);
    },
  },
});
