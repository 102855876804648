
import Vue from "vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";

export default Vue.extend({
  name: "InputCheckList",
  components: { InputWrapper },
  props: {
    value: Array,
    fieldKey: String,
    field: Object,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
});
