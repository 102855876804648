
import Vue from "vue";
// @ts-ignore
import { computeColorFromScoring } from "@/utils/colors";
import { mapboxInstance } from "@/mapbox";
import {
  FIELDS_CONFIGURATION, // @ts-ignore
  PARTNERS_FIELDS, // @ts-ignore
} from "@/utils/sidebarData";
import InputCheck from "@/components/inputs/InputCheck.vue";

interface IsHidden {
  [key: string]: boolean;
}

const isHidden: IsHidden = {};
for (const category of FIELDS_CONFIGURATION) {
  isHidden[category.ref] = false;
}

export default Vue.extend({
  name: "IndicatorsFilter",
  components: { InputCheck },
  data() {
    return {
      fieldCategories: FIELDS_CONFIGURATION,
      PARTNERS_FIELDS: PARTNERS_FIELDS,
      isHidden: isHidden,
      isOpened: true,
    };
  },
  computed: {
    isSenior() {
      return this.$store.state.map.isSenior;
    },
  },
  methods: {
    async toggleIsSenior() {
      await this.$store.commit("TOGGLE_SENIOR");
      this.updateScore();
    },
    updateFilters(payload: object) {
      this.$store.commit("SET_FILTER", payload);
      this.updateScore();
    },
    updateScore() {
      this.$store
        .dispatch(
          "FETCH_SCORES",
          Object.keys(this.$store.state.map.showingCities).filter(
            (k) => this.$store.state.map.showingCities[k] === true
          )
        )
        .then((res) => {
          const scores = res.data;
          for (const [inseeCode, info] of Object.entries(scores) as any) {
            if (mapboxInstance.map?.getLayer(inseeCode)) {
              const layerProperties = computeColorFromScoring(info.score && info.score.total);
              const opacity = (info.score && info.score.total) == null ? 0 : 0.8;
              mapboxInstance.map?.setPaintProperty(inseeCode, "fill-color", layerProperties);
              mapboxInstance.map?.setPaintProperty(inseeCode, "fill-opacity", opacity);
            }
          }
          const currentInseeCode = this.$store.state.map.currentInseeCode;
          if (scores[currentInseeCode]) {
            this.$store.commit("SET_GLOBAL_SCORE", scores[currentInseeCode]);
          }
        });
    },
  },
});
