
// @ts-ignore
import { FIELDS_CONFIGURATION } from "@/utils/sidebarData";

import Vue from "vue";
import { isSiteMH } from "@/utils/utils";
import Cross from "@/components/logo/cross.vue";
export default Vue.extend({
  name: "ScoreInformation",
  components: { Cross },
  props: {
    modalInformation: {
      type: Object,
    },
  },
  data() {
    return {
      FIELDS_CONFIGURATION,
      isMH: isSiteMH(),
    };
  },
  computed: {
    isSenior() {
      return this.$store.state.map.isSenior;
    },
  },
  methods: {
    scoreOrUnknown(score: number): string {
      if (score == null) {
        return "inconnu";
      }
      return String(score);
    },
    closeWindow() {
      this.$emit("close");
    },
  },
});
