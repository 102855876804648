
import Vue from "vue";
import Map from "@/components/Map.vue";
import Sidebar from "@/components/Sidebar.vue";
import MapInformations from "@/components/MapInformations.vue";
import Modal from "@/components/Modal.vue";

export default Vue.extend({
  name: "App",
  components: {
    Map,
    Sidebar,
    MapInformations,
    Modal,
  },
  created() {
    if (localStorage.getItem("hasSeenModal")) this.$store.commit("SET_SEEN_MODAL");
  },
  computed: {
    showModal: {
      get(): boolean {
        return this.$store.state.global.showModal;
      },
    },
    hasSeenModal(): boolean {
      return this.$store.state.global.hasSeenModal;
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {},
});
