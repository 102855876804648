
import Vue from "vue";

export default Vue.extend({
  name: "InputWrapper",
  props: {
    fieldKey: String,
    field: Object,
  },
});
