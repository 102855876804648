export const ZOOM_LEVELS = {
  city: 10,
  epci: 8,
  departement: 6,
};

export const MAX_ZOOM_LEVELS = {
  city: 8.7,
  epci: 6.7,
};
