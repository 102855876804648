
import Vue from "vue";
import MapFooter from "@/components/MapFooter.vue";
import IndicatorsFilter from "@/components/filters/IndicatorsFilter.vue";
import FranceServiceFilter from "@/components/filters/FranceServiceFilter.vue";
import PartnersFilter from "@/components/filters/PartnersFilter.vue";

export default Vue.extend({
  name: "Sidebar",
  components: { MapFooter, IndicatorsFilter, FranceServiceFilter, PartnersFilter },
});
